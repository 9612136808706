<div class="Flex--col" style="height:100%">

  <!-- ########### BEGIN Data components and tools ################-->
  <Data name="homeExtent" :value="[7.8,49.77,9.62,48.95]" />
  <Data name="mapHomeTrigger" :value="false" />
  <Data name="showLegend" :value="false" />
  <Data name="showGfiWindow" :value="false" />
  <Data name="showLayerTree" :value="true" />
  <Data name="activeTool" value="routing" />
  <Data name="ckanApiKey" value="" />
  <Data name="routingResult" value="null" />
  
  <DataJsonFetch name="layerDef" url="layerdefs.json" />

  <!--
  <DataJsonFetch name="entities" url="http://136.243.89.30:5000/ngsi-ld/v1/entities/?georel=near;maxDistance==50&geometry=LineString&coordinates=[[8.641,48.2816],[8.6383,48.2681],[8.6502,48.2357],[8.6502,48.2295],[8.6477,48.2236],[8.6408,48.216],[8.6303,48.209],[8.5989,48.1981],[8.5889,48.193],[8.5853,48.1905],[8.5812,48.1869],[8.5775,48.1827],[8.5758,48.1799],[8.575,48.1785],[8.5748,48.1782],[8.5746,48.1777],[8.5745,48.1776],[8.5741,48.1769],[8.5728,48.1767],[8.5728,48.1779],[8.574,48.1778],[8.5746,48.1777],[8.5748,48.1777],[8.575,48.1777],[8.5754,48.1776],[8.5756,48.1765],[8.5744,48.1769],[8.5748,48.1777],[8.575,48.1781],[8.5752,48.1786],[8.5754,48.179],[8.5771,48.1818],[8.5851,48.1901]]&geoproperty=location" />
  -->

  <DataMap name="map" />
  <DataMapLayer name="basemap" :map="local.map" :visible="true" :layerDef="local.layerDef" layerId="basemap_osm_grau" />
  <DataCkanLayersTree name="ckanLayersTree" :ckanApiKey="local.ckanApiKey" :map="local.map" url="https://ckan.geonet-mrn.de/api/3/action/current_package_list_with_resources?limit=10000" />

  <SetMapExtentTool :map="local.map" :trigger="local.mapHomeTrigger" :extent="local.homeExtent" />
  <!-- ########### END Data components and tools ################-->


  <div class="App Flex--col" style="height:100%">

    <Toolbar>
      <Superbutton label="Datenkatalog" v-model="local.showLayerTree" icon="img/tree2.svg" :set="true" :unset="false" />
      <Superbutton label="Aktive Ebenen" v-model="local.showLegend" icon="tivigi/img/layers.svg" :set="true" :unset="false" />
      <Superbutton label="Routing" v-model="local.activeTool" icon="img/route.svg" set="routing" unset="gfi" />
      <Superbutton label="Überblick MRN" icon="img/home.svg" @click="local.mapHomeTrigger = !local.mapHomeTrigger" title="Die Karte auf die Start-Ansicht zurücksetzen. Die Startansicht gewährt einen vollständigen Überblick über die Metropolregion Rhein-Neckar." />
      <div style="flex:1"></div>


      <LocationSearch :map="local.map" />


    </Toolbar>


    <div class="Flex--row">

    <FlexCol>
          <FloatingWindow title="Datenkatalog" :height="600" :width="350" style="width:350px" :show.sync="local.showLayerTree" :docked="true">
            <CkanLogin ckanBaseUrl="https://ckan.geonet-mrn.de" :ckanApiKey.sync="local.ckanApiKey" />

            <Tree :rootNode="local.ckanLayersTree" :showFilterInput="true" />
          </FloatingWindow>


          <FloatingWindow title="NGSI-LD Test: Baustellen entlang der Route" :height="600" :width="350" style="width:350px" :show.sync="local.showLayerTree" :docked="true">
            <NgsiLdEntityList :route="local.routingResult"/>
          </FloatingWindow>
    </FlexCol>


      <!--####################### BEGIN Main panel ############################-->
      <div class="App_MainPanel Flex--row">
        <MapPanel :map="local.map" ref="mapPanel" :initialExtent="local.homeExtent" />
      </div>
      <!--####################### END Main panel ############################-->

      <!--#################### BEGIN Right Sidebar ##################-->
      <FlexCol style="width:450px;max-width:40%;">

        <FloatingWindow key="legend" title="Aktive Ebenen" :width="350" :height="600" :docked="true" :dockButton="true" :show.sync="local.showLegend">
          <ActiveLayers :map="local.map" :attributesTableButton="false" style="padding:13px" ckanApiUrl="https://daten.digitale-mrn.de/api/3/" :buttonsConfig="['menu', 'remove']" :menuConfig="['attributes', 'fit_extent']" />
        </FloatingWindow>

        <!--
        <div v-if="local.activeTool == 'routing'" class="Flex--col">
-->




        <FloatingWindow key="routing" v-if="local.activeTool == 'routing'" title="HERE Routing" :docked="true" :dockButton="true" @closeButtonClick="local.activeTool='gfi'">

          <div style="padding:13px;">
            <Superbutton label="Route berechnen" v-model="local.showRouteCalculator" :set="true" :unset="false" />
          </div>

          <FloatingWindow v-if="local.showRouteCalculator" title="HERE Routing" :docked="false" :dockButton="false" @closeButtonClick="local.showRouteCalculator=false" :height="600" :width="520">

            <HereRouting :map="local.map" hereApiKey="SW_Y4i1tFvKJ4AfhTpv7u4PwkIxbGxW3XOWO_tA75mI" 
            style="padding:13px;" :routingResult.sync="local.routingResult" />
          </FloatingWindow>

          <HereRouteInstructionsView :route="local.routingResult" @actionClick="onActionClick" @actionMouseOver="onActionMouseOver" />
          <HereRouteMapLayerView :map="local.map" :route="local.routingResult" />
        </FloatingWindow>
        <!--
        </div>
-->


        <FloatingWindow v-if="local.activeTool=='gfi'" :show.sync="local.showGfiWindow" :docked="false" :dockButton="true" :title="local.gfiWindowTitle">
          <FeatureInfoTool :show.sync="local.showGfiWindow" :map="local.map" style="padding:13px;" :title.sync="local.gfiWindowTitle" />
        </FloatingWindow>

      </FlexCol>
      <!--#################### END Right Sidebar ##################-->

    </div>



  </div>


</div>
