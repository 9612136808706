import Vue from 'vue'
import App from './components/App/App'
import router from './router'
import { tabfocusoutline } from 'tivigi/src/util/tabfocusoutline'
import * as pf from "tivigi/src/util/proxyfetch"

import "tivigi/src/util/vueFilters"


import "./globals.scss"


// Configure proxy url:
pf.config.proxyUrl = "https://xdataviewer.geonet-mrn.de/proxy.php?csurl="

// Enable tab focus outline:
tabfocusoutline()

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

