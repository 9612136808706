import { Component, Vue, Watch } from 'vue-property-decorator';


//################ BEGIN Data imports #################
import DataMap from 'tivigi/src/components/gis/data/DataMap/DataMap'
import DataMapLayer from 'tivigi/src/components/gis/data/DataMapLayer/DataMapLayer'
import Data from 'tivigi/src/components/data/Data/Data'
import DataJsonFetch from 'tivigi/src/components/data/DataJsonFetch/DataJsonFetch'
import DataCkanLayersTree from 'tivigi/src/components/gis/data/DataCkanLayersTree/DataCkanLayersTree'
//################ END Data imports #################


//############## BEGIN Tivigi components ###############
import AnalogGauge from 'tivigi/src/components/charts/AnalogGauge/AnalogGauge'
import Collapsible from 'tivigi/src/components/Collapsible/Collapsible'

import DropdownButton from 'tivigi/src/components/DropdownButton/DropdownButton'
import DropdownMenu from 'tivigi/src/components/DropdownMenu/DropdownMenu'
import FlexCol from 'tivigi/src/components/FlexCol/FlexCol'
import FlexRow from 'tivigi/src/components/FlexRow/FlexRow'
import FloatingWindow from 'tivigi/src/components/FloatingWindow/FloatingWindow'
import JsonView from 'tivigi/src/components/JsonView/JsonView'
import CkanLogin from 'tivigi/src/components/CkanLogin/CkanLogin'
import Modal from 'tivigi/src/components/Modal/Modal'
import ProgressBar from 'tivigi/src/components/ProgressBar/ProgressBar'
import Slide from 'tivigi/src/components/Slideshow/Slide'
import Slideshow from 'tivigi/src/components/Slideshow/Slideshow'
import Superbutton from 'tivigi/src/components/Superbutton/Superbutton'
import Toolbar from 'tivigi/src/components/Toolbar/Toolbar'
//############## END Tivigi components ###############


//######################## BEGIN tivigi GIS imports ###########################
import ActiveLayers from 'tivigi/src/components/gis/ActiveLayers/ActiveLayers'
import FeatureSelectTool from 'tivigi/src/components/gis/FeatureSelectTool/FeatureSelectTool'
import FeatureInfoTool from 'tivigi/src/components/gis/FeatureInfoTool/FeatureInfoTool'
import Tree from 'tivigi/src/components/Tree/Tree'
import LocationInput from 'tivigi/src/components/gis/LocationInput/LocationInput'
import LocationSearch from 'tivigi/src/components/gis/LocationSearch/LocationSearch'
import MapLoadingProgressBar from 'tivigi/src/components/gis/MapLoadingProgressBar/MapLoadingProgressBar'
import MapOverlay from 'tivigi/src/components/gis/MapOverlay/MapOverlay'
import MapPanel, { MapDropEvent, MapDragEvent } from 'tivigi/src/components/gis/MapPanel/MapPanel'
import MapScale from 'tivigi/src/components/gis/MapScale/MapScale'
import MeasureTool from 'tivigi/src/components/gis/MeasureTool/MeasureTool'
import MouseCoordinatesTool from 'tivigi/src/components/gis/MouseCoordinatesTool/MouseCoordinatesTool'
import MyLocationTool from 'tivigi/src/components/gis/MyLocationTool/MyLocationTool'
import SetMapExtentTool from 'tivigi/src/components/gis/SetMapExtentTool/SetMapExtentTool'
//######################## END tivigi GIS imports ###########################

import HereRouting from 'tivigi/src/components/gis/HereRouting/HereRouting/HereRouting'
import HereRouteInstructionsView from 'tivigi/src/components/gis/HereRouting/HereRouteInstructionsView/HereRouteInstructionsView'
import HereRouteMapLayerView from 'tivigi/src/components/gis/HereRouting/HereRouteMapLayerView/HereRouteMapLayerView'


import NgsiLdEntityList from 'tivigi/src/components/ngsi-ld/NgsiLdEntityList/NgsiLdEntityList'

import WithRender from './App.html';
import './App.scss'



@WithRender
@Component({
    components: {


        //############## BEGIN Data components ################
        DataMap,
        DataMapLayer,
        Data,
        DataCkanLayersTree,        
        DataJsonFetch: DataJsonFetch,
        //############## END Data components ################


        //############## BEGIN GIS components ################
        ActiveLayers,    
        FeatureInfoTool,
        FeatureSelectTool,
        HereRouting,
        HereRouteInstructionsView,
        HereRouteMapLayerView,
        LocationInput,
        LocationSearch,
        MapLoadingProgressBar,
        MapOverlay,
        MapPanel,
        MapScale,
        MeasureTool,
        MouseCoordinatesTool,
        MyLocationTool,
        SetMapExtentTool,
        //############## END GIS components ################

        //################# BEGIN Misc tivigi components #############        
        AnalogGauge,
        Collapsible,       
        DropdownButton,
        DropdownMenu,
        FlexCol,
        FlexRow,
        FloatingWindow,
        JsonView,
        CkanLogin,
        Modal,
        ProgressBar,
        Slide,
        Slideshow,
        Superbutton,
        Toolbar,
        Tree,
        //################# END Misc tivigi components #############

        NgsiLdEntityList
       
    }
})
export default class App extends Vue {

   
    local: any = {}
   actionUnderMouse : any = null
   


    onActionClick(action : any) {
        console.log(action)
        console.log("go")
    }


    onActionMouseOver(action : any) {

        if (action == null) {
            if (this.actionUnderMouse != null) {
            //    delete(this.actionUnderMouse.mouseOver)
        }
        }
        else {
            action.mouseOver = true
        }

        this.actionUnderMouse = action
       

    
    }
}
